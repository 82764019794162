<template>
    <div class="DigitalTwin">
        <p class="p justify">
            我们将主要为新材料、半导体行业工厂提供实现制造端和研发段的孪生系统可视化搭建以及仿真模拟服务。通过在虚拟系统内部的分析和优化，帮助工厂在研发端和生产端降本增效。
        </p>
        <el-tag
            effect="dark"
        >
            案例：数据中心数字孪生系统
        </el-tag>
        <p class="p justify">
            我们为某知名数据中心服务商，提供分阶段的数据中心数字孪生系统解决方案。
        </p>
        <p class="p justify">
            <strong>三维模型层面：</strong>我们以实际机房为蓝本，采用实时引擎渲染的方法建立MDC模块化数据中心Web交互式三维虚拟模型，通过数字化形式呈现其内部结构与运行状态。该模型可适配多终端展示与交互，可随时随地演示操作，从而帮助企业实现有品位的市场营销及品牌价值的提升。
        </p>
        <p class="p justify">
            <strong>物理仿真层面：</strong>数据中心数字孪生系统相当于DCIM系统的升级版，充分利用物联网、有限元分析、大数据和人工智能算法构建机理-数据耦合驱动模型，实现管理和展示需求的可视化和可操作化，以及预制、运营阶段的智能在线分析、决策，从而更加有效地对数据中心进行热管理、降低能耗、提升运行效率。
        </p>
        <img
            class="img-info"
            src="@/assets/images/digital-twin/shuziluansheng.png"
        >
    </div>
</template>

<script>
export default {
  name: 'DigitalTwin',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', '工业数字孪生解决方案')
      }
}
</script>
<style lang="less" scoped>
.DigitalTwin{
    margin-bottom:6.25rem;
    /deep/.el-tag--dark{
      margin-top:3.125rem!important;
    }
    .img-info{
      width: 70%;
      margin: 0 auto;
      display: block;
    }
}
</style>
